import React from "react";
import OrangeHeader from "../components/OrangeHeader";
import HeroSection from "../components/HeroSection";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <OrangeHeader />
      
    </>
  );
};

export default HomePage;
